<template>
	<div class="row">

      <!-- pesquisa -->
      <div id="search-nav" class="col-12 d-block btn-group dropup">
         <button type="button" class="btn btn-primary dropdown-toggle d-block d-lg-none" data-bs-toggle="dropdown" aria-expanded="false">
            <i class="far fa-search font-17"></i>
         </button>
         <div class="card mb-2 dropdown-menu dropdown-menu-left">
            <div class="card-body pb-0">
               <div class="row m-0">
                  <div class="col-12 col-sm-8 col-md-10 mb-3 px-1">
                     <label><i class="far fa-search color-theme font-12 me-1"></i> Pesquisar</label>
                     <input type="text" class="form-control" v-model="pesquisa.nome" maxlength="200" placeholder="Buscar por nome, username, Whatsapp ou email" @keyup.enter="searchAllUsuarios" />
                  </div>
                  <div class="col-12 col-sm-4 col-md-2 mb-3 px-1 align-self-end">
                     <button type="button" class="btn btn-primary w-100" @click="searchAllUsuarios">
                        <i class="fas fa-search font-13"></i>
                     </button>
                  </div>
               </div>
            </div>
         </div>
      </div>

      <!-- usuario card -->
      <div class="col-12" ref="usuario" v-for="(usuario, index) in pesquisa.resultado" :key="index">
         <div class="card mb-1" :class="index % 2 == 0 ? 'invert' : ''">
            <div class="card-body d-flex p-3 pe-1">
               <div class="icon d-none d-xl-inline">
                  <img :src="usuario.avatar == null ? '' : usuario.avatar" alt="Icone" @error="imageError">
               </div>

               <div class="col">
                  <div class="row m-0 font-12">
                     <div class="col-md-8 col-lg-9 px-1 align-self-center mb-2 mb-md-0">
                        <div class="row m-0">
                           <div class="col-12 p-0 text-center text-md-start mb-1">
                              <div class="limitador font-16 weight-600">{{ usuario.nomeCompleto }}</div>
                           </div>
                           <div class="col-6 p-0 d-none d-md-inline">
                              <div class="limitador">
                                 <i class="fab fa-discord color-theme font-10 me-1"></i><strong class="me-1 d-none d-xl-inline text-secondary"> Username:</strong> 
                                 {{ usuario.userName }}
                              </div>
                           </div>
                           <div class="col-6 p-0 d-none d-md-inline">
                              <div class="limitador">
                                 <i class="fab fa-whatsapp color-theme font-10 me-1"></i><strong class="me-1 d-none d-xl-inline text-secondary"> Whatsapp:</strong> 
                                 {{ usuario.whatsApp == null ? 'Desconhecido' : usuario.whatsApp }}
                              </div>
                           </div>
                           <div class="col-6 p-0 d-none d-md-inline">
                              <div class="limitador">
                                 <i class="far fa-envelope color-theme font-10 me-1"></i><strong class="me-1 d-none d-xl-inline text-secondary"> Email:</strong> 
                                 {{ usuario.email == null ? 'Desconhecido' : usuario.email }}
                              </div>
                           </div>
                           <div class="col-6 p-0 d-none d-md-inline">
                              <div class="limitador">
                                 <i class="far fa-id-card-alt color-theme font-10 me-1"></i><strong class="me-1 d-none d-xl-inline text-secondary"> Permissões: </strong> 
                                 <i class="fas fa-key color-theme me-2 font-10" v-if="usuario.isAdmin" title="Admin"></i>
                                 <i class="fas fa-user-headset text-info me-2 font-10" v-if="usuario.isSuporte" title="Suporte"></i>
                                 <i class="fas fa-user text-success font-10"></i>
                              </div>
                           </div>
                        </div>
                     </div>
                     
                     <div class="col-md-4 col-lg-3 d-flex px-1 font-16 align-self-center text-center">
                        <span class="w-25 btn-icone">
                           <i class="fal fa-backpack" @click="verPacotes(usuario)"></i><small>Pacotes</small>
                        </span>
                        <span class="w-25 btn-icone">
                           <i class="fal fa-sort-amount-up-alt" @click="searchJornada(usuario)"></i><small>Jornada</small>
                        </span>
                        <span class="w-25 btn-icone">
                           <i class="fal fa-pen" @click="editUser(usuario)"></i><small>Editar</small>
                        </span>
                        <span class="w-25 btn-icone text-danger">
                           <i class="far fa-trash" @click="deletarUser(usuario)"></i><small>Excluir</small>
                        </span>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </div>
      
      <!-- modalPacotes -->
      <div class="modal fade" id="modalPacotes" aria-labelledby="modalPacotesLabel" aria-hidden="true">
         <div class="modal-dialog modal-xxl modal-dialog-centered modal-dialog-scrollable h-100">
            <div class="modal-content h-100">
               <div class="modal-header">
                  <h5 class="modal-title" id="modalPacotesLabel" v-if="modalPacote.toggle">Adicionar pacote</h5>
                  <h5 class="modal-title" id="modalPacotesLabel" v-else>Pacotes</h5>
                  <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
               </div>
               <div class="modal-body">
                  <div class="row">
                     <div class="col-12" v-if="modalPacote.toggle">
                        <div class="cards-1">
                           <div class="card mb-0" v-for="(pacote, index) in listaPacotes" :key="index">
                              <div class="card-buttons-top">
                                 <div class="card-body">
                                    <div id="buttons" class="w-100 text-center pb-5">
                                       <div class="badge bg-theme cursor-pointer" @click="adicionarPacote(pacote)">
                                          <i class="far fa-plus font-11 me-1"></i> Adicionar
                                       </div>
                                    </div>
                                    <h6 class="limitador-2" >{{ pacote.nome }}</h6>

                                    <div class="row card-info-bottom align-items-center">
                                       <div class="col-6">
                                          <h6 class="limitador-2 font-16"><small class="color-theme">R$</small> {{ parseFloat(pacote.valor).toFixed(2) }}</h6>
                                       </div>
                                       <div class="col-6 text-end">
                                          <i class="fas fa-percentage font-16 color-theme me-3" v-if="pacote.isPromocao"></i>
                                          <i class="fas fa-eye-slash font-16 color-theme me-3" v-if="pacote.isDesabilitado"></i>
                                          <i class="fas fa-star font-16 color-theme" v-if="pacote.isDestaque"></i>
                                       </div>
                                       <div class="col-12 mt-1">
                                          <p class="limitador-3"><i class="fas fa-info-circle font-12 color-theme me-1"></i> {{ pacote.descricao != null ? pacote.descricao : 'Sem descrição' }}</p>
                                       </div>
                                       <div class="col-12 mt-2" v-if="pacote.linkDocumentacao">
                                          <a :href="pacote.linkDocumentacao" target="_blank" class="font-13 d-block limitador mb-1">
                                             <i class="fas fa-file-alt font-12 color-theme me-1"></i> {{ pacote.linkDocumentacao }}
                                          </a>
                                       </div>
                                    </div>
                                 </div>
                              </div>
                              <img :src="pacote.nomeArquivo == null ? '' : pacote.nomeArquivo" class="card-img-top" alt="noticia" @error="imageError">
                           </div>
                        </div>
                        <div class="w-100 my-5 text-center" v-if="listaPacotes.length == 0">Nenhum resultado encontrado</div>
                     </div>
                     <div class="col-12" v-else>
                        <div class="cards-1">
                           <div class="card mb-0" v-for="(pacote, index) in modalPacote.pacotes" :key="index">
                              <div class="card-buttons-top">
                                 <div class="card-body">
                                    <div id="buttons" class="w-100 text-center pb-5">
                                       <div class="badge bg-dark cursor-pointer" @click="removerPacote(pacote, index)">
                                          <i class="far fa-trash font-11 me-1"></i> Remover
                                       </div>
                                    </div>
                                    <h6 class="limitador-2" >{{ pacote.nome }}</h6>
                                    <p class="limitador-3"><i class="far fa-info-circle font-12 color-theme me-1"></i> {{ pacote.descricao != null ? pacote.descricao : 'Sem descrição' }}</p>
                                 </div>
                              </div>
                              <img :src="pacote.nomeArquivo == null ? '' : pacote.nomeArquivo" class="card-img-top" alt="noticia" @error="imageError">
                           </div>
                        </div>
                        <div class="w-100 my-5 text-center" v-if="modalPacote.pacotes.length == 0">Nenhum resultado encontrado</div>
                     </div>
                  </div>
               </div>
               <div class="modal-footer">
                  <button type="button" class="btn btn-round btn-primary" @click="modalPacote.toggle = true" v-if="!modalPacote.toggle">Adicionar</button>
                  <button type="button" class="btn btn-round btn-primary" @click="modalPacote.toggle = false" v-else>Voltar</button>
                  <button type="button" class="btn btn-round btn-secondary" data-bs-dismiss="modal">Fechar</button>
               </div>
            </div>
         </div>
      </div>

      <!-- modalPerfil -->
      <!-- <div class="modal fade" id="modalPerfil" aria-labelledby="modalPerfilLabel" aria-hidden="true">
         <div class="modal-dialog modal-xl modal-dialog-centered modal-dialog-scrollable">
            <div class="modal-content">
               <div class="modal-header">
                  <h5 class="modal-title" id="modalPerfilLabel">Sobre</h5>
                  <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
               </div>
               <div class="modal-body">
                  <div class="card mb-2 p-sm-3">
                     <div class="card-body p-sm-4">
                        <div class="row">
                           <div class="wpx-250 align-self-center mx-auto mx-md-0 mb-3 mb-md-0">
                              <img width="100%" class="rounded" :src="perfil.avatar == null ? '' : perfil.avatar" alt="banner" @error="imageError">
                           </div>
                           <div class="col-md">
                              <div class="row">
                                 <div class="col-12">
                                    <h4 class="limitador">{{ perfil.nomeCompleto }}</h4>
                                 </div>
                                 <div class="col-md-6">
                                    <small class="text-muted"><i class="fab fa-discord font-10"></i> User do discord: </small>
                                    <p>{{perfil.userName != null && perfil.userName != '' ? perfil.userName : '*Não registrado'}}</p>
                                    <small class="text-muted"><i class="fas fa-id-card-alt font-10"></i> CPF/CNPJ: </small>
                                    <p>{{perfil.documento != null && perfil.documento != '' ? perfil.documento : '*Não registrado'}}</p>
                                 </div>
                                 <div class="col-md-6">
                                    <small class="text-muted"><i class="fas fa-envelope font-10"></i> Email: </small>
                                    <p>{{perfil.email != null && perfil.email != '' ? perfil.email : '*Não registrado'}}</p>
                                    <small class="text-muted"><i class="fab fa-whatsapp font-10"></i> Whatsapp: </small>
                                    <p>{{perfil.whatsApp != null && perfil.whatsApp != '' ? perfil.whatsApp : '*Não registrado'}}</p>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </div> -->

      <!-- modalJornada -->
      <div class="modal fade" id="modalJornada" aria-labelledby="modalJornadaLabel" aria-hidden="true">
         <div class="modal-dialog modal-md modal-dialog-centered modal-dialog-scrollable h-100">
            <div class="modal-content h-100">
               <div class="modal-header">
                  <h5 class="modal-title" id="modalJornadaLabel">Jornada</h5>
                  <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
               </div>
               <div class="modal-body">
                  <div class="card mb-2">
                     <div class="card-body">
                        <ul class="nav nav-pills" id="pills-tab" role="tablist">
                           <li class="nav-item w-50" role="presentation">
                              <button class="nav-link m-0 active" id="pills-profile-tab" data-bs-toggle="pill" data-bs-target="#pills-profile" type="button" role="tab" aria-controls="pills-profile" aria-selected="true">
                                 Geral
                              </button>
                           </li>
                           <li class="nav-item w-50" role="presentation">
                              <button class="nav-link m-0" id="pills-home-tab" data-bs-toggle="pill" data-bs-target="#pills-home" type="button" role="tab" aria-controls="pills-home" aria-selected="false">
                                 Aulas
                              </button>
                           </li>
                        </ul>
                     </div>
                  </div>

                  <div class="tab-content" id="pills-tabContent">
                     <div class="tab-pane fade" id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab">
                        <div class="card mb-1" :class="index % 2 == 0 ? 'invert' : ''" v-for="(log, index) in jornada.aulas" :key="index">
                           <div class="card-body p-12">
                              <h6 class="font-16 mb-1">
                                 <i class="fas fa-eye me-2 font-15 color-theme"></i>
                                 <span>{{ log.descricao }}</span>
                              </h6>
                              <p class="font-12 limitador text-secondary mb-0">{{ formatDateTime(log.data) }}</p>
                           </div>                           
                        </div>
                        <div class="w-100 my-5 text-center" v-if="jornada.aulas.length == 0">Nenhum resultado encontrado</div>
                     </div>
                     <div class="tab-pane fade show active" id="pills-profile" role="tabpanel" aria-labelledby="pills-profile-tab">
                        <div class="card mb-1" :class="index % 2 == 0 ? 'invert' : ''" v-for="(log, index) in jornada.geral" :key="index">
                           <div class="card-body p-12">
                              <h6 class="font-16 mb-1">
                                 <i class="fas fa-quote-right me-2 font-15 color-theme"></i>
                                 <span>{{ log.descricao }}</span>
                              </h6>
                              <p class="font-12 limitador text-secondary mb-0">{{ formatDateTime(log.data) }}</p>
                           </div>                           
                        </div>
                        <div class="w-100 my-5 text-center" v-if="jornada.geral.length == 0">Nenhum resultado encontrado</div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </div>

      <!-- modalEditUser -->
      <div class="modal fade" id="modalEditUser" aria-labelledby="modalEditUserLabel" aria-hidden="true">
         <div class="modal-dialog modal-xl modal-dialog-centered modal-dialog-scrollable">
            <div class="modal-content">
               <div class="modal-header">
                  <h5 class="modal-title" id="modalEditUserLabel">Editar</h5>
                  <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
               </div>
               <div class="modal-body">
                  <div class="card mb-1">
                     <div class="card-body p-12">
                        <div class="row m-0">
                           <div class="col-12 mb-3 px-1">
                              <label><i class="far fa-user font-13 color-theme me-1"></i> Nome completo</label>
                              <input type="text" class="form-control" maxlength="200" v-model="user.nomeCompleto"/>
                           </div>
                           <div class="col-6 mb-3 px-1">
                              <label><i class="far fa-id-card-alt font-13 color-theme me-1"></i> CPF / CNPJ</label>
                              <input type="text" class="form-control" maxlength="200" v-model="user.documento"/>
                           </div>
                           <div class="col-6 mb-3 px-1">
                              <label><i class="fab fa-whatsapp font-13 color-theme me-1"></i> Whatsapp</label>
                              <input type="text" class="form-control" maxlength="200" v-model="user.whatsApp"/>
                           </div>
                           <div class="col-12 mb-3 px-1">
                              <label><i class="far fa-envelope font-13 color-theme me-1"></i> Email</label>
                              <input type="text" class="form-control" maxlength="200" v-model="user.email"/>
                           </div>
                           <div class="col-6 mb-3 px-1 d-flex justify-content-start" v-if="dadosUsuario.isAdmin && user.id != dadosUsuario.id">
                              <div class="form-check mb-0">
                                 <input type="checkbox" class="form-check-input" id="flexCheckDefault10" v-model="user.isAdmin">
                                 <label class="form-check-label cursor-pointer" for="flexCheckDefault10"><i class="fas fa-key font-13 color-theme me-1"></i> Administrador</label>
                              </div>
                           </div>
                           <div class="col-6 mb-3 px-1 d-flex justify-content-end" v-if="dadosUsuario.isAdmin && user.id != dadosUsuario.id">
                              <div class="form-check mb-0">
                                 <input type="checkbox" class="form-check-input" id="flexCheckDefault10" v-model="user.isSuporte">
                                 <label class="form-check-label cursor-pointer" for="flexCheckDefault10"><i class="fas fa-user-headset font-13 text-info me-1"></i> Suporte</label>
                              </div>
                           </div>
                        </div>
                     </div>                           
                  </div>
               </div>
               <div class="modal-footer">
                  <button type="button" class="btn btn-round btn-primary" @click="saveDadosUsuario">Salvar</button>
                  <button type="button" class="btn btn-round btn-secondary" data-bs-dismiss="modal">Fechar</button>
               </div>
            </div>
         </div>
      </div>
   </div>
</template>

<script>

import $ from 'jquery'
import { mapState } from 'vuex'
import Swal from 'sweetalert2'

export default {
	name: 'Usuarios',
	data: function() {
		return {
         pesquisa: {nome: '', 'resultado': []},
         modalPacote: {'pacotes': [], 'toggle': false, 'idUsuario': null},
         jornada: {'aulas': [], 'geral': []},
         user: {},
         entrouEm: null,
         showLine: localStorage.getItem('showLine') != null ? localStorage.getItem('showLine') : 'card'
		}
	},
	computed: {
		...mapState({
			dadosUsuario:  state => state.dadosUsuario,
         urlRest: state => state.urlRest,
         listaPacotes: state => state.listaPacotes
		})
	},
   methods: {
      searchAllUsuarios : function () {
         let ref = this;
         this.$store.dispatch('alternarTelaCarregamento', true)
         this.pesquisa.resultado = [];

         this.$axios({
            method: 'get',
            url: this.urlRest +'servicosAdmin/searchAllUsuarios',
            params: {
               valor: this.pesquisa.nome
            }
         }).then(response => {
				ref.pesquisa.resultado = response.data;

         }).catch(function (error) {
            if (error.response != undefined) {
               if (error.response.status == 408) {
                  ref.$store.dispatch('deslogar')

                  ref.$toast.fire({
                     icon: 'warning',
                     title: 'Sessão expirada!'
                  });
               } else {
                  ref.$toast.fire({
                     icon: 'error',
                     title: 'Erro: ' + error.response.status
                  });
               }
            } else {
               ref.$toast.fire({
                  icon: 'error',
                  title: error
               });
            }
         }).finally(() => {
            this.$store.dispatch('alternarTelaCarregamento', false)
         });
      },
      searchJornada : function (usuario) {
         let ref = this;
         this.$store.dispatch('alternarTelaCarregamento', true)
         this.jornada.aulas = [];
         this.jornada.geral = [];

         this.$axios({
            method: 'get',
            url: this.urlRest +'servicosAdmin/searchJornada',
            params: {
               idUsuario: usuario.id
            }
         }).then(response => {
				ref.jornada = response.data;

             $('#modalJornada').modal('show')

         }).catch(function (error) {
            if (error.response != undefined) {
               if (error.response.status == 408) {
                  ref.$store.dispatch('deslogar')

                  ref.$toast.fire({
                     icon: 'warning',
                     title: 'Sessão expirada!'
                  });
               } else {
                  ref.$toast.fire({
                     icon: 'error',
                     title: 'Erro: ' + error.response.status
                  });
               }
            } else {
               ref.$toast.fire({
                  icon: 'error',
                  title: error
               });
            }
         }).finally(() => {
            this.$store.dispatch('alternarTelaCarregamento', false)
         });
      },
      setUsuarioDeletado : function (usuario) {
         let ref = this;

         this.$store.dispatch('alternarTelaCarregamento', true)
         this.$axios({
            method: 'post',
            url: this.urlRest +'servicosAdmin/setUsuarioDeletado',
            params: {
               idUsuarioDeletado: usuario.id,
               idUsuario: this.dadosUsuario.id,
            }
         }).then(function () {

         }).catch(function (error) {
            if (error.response != undefined) {
               if (error.response.status == 408) {
                  ref.$store.dispatch('deslogar')

                  ref.$toast.fire({
                     icon: 'warning',
                     title: 'Sessão expirada!'
                  });
               } else {
                  ref.$toast.fire({
                     icon: 'error',
                     title: 'Erro: ' + error.response.status
                  });
               }
            } else {
               ref.$toast.fire({
                  icon: 'error',
                  title: error
               });
            }
         }).finally(() => {
            this.$store.dispatch('alternarTelaCarregamento', false)
         });
      },
      saveDadosUsuario : function () {
         let ref = this;

			this.$store.dispatch('alternarTelaCarregamento', true)
         this.$axios({
            method: 'post',
            url: this.urlRest +'servicosAdmin/saveDadosUsuario',
            headers: {
					'Content-Type': 'application/json'
            },
				data: this.user

         }).then(function () {
            ref.searchAllUsuarios()
            $('#modalEditUser').modal('hide')
            ref.$toast.fire({
               icon: 'success',
               title: 'Salvo!',
               timer: 1500
            })
         }).catch(function (error) {
            if (error.response != undefined) {
               if (error.response.status == 408) {
                  ref.$store.dispatch('deslogar')

                  ref.$toast.fire({
                     icon: 'warning',
                     title: 'Sessão expirada!'
                  });
               } else if (error.response.status == 400) {
                  ref.$toast.fire({
                     icon: 'error',
                     title: 'Data inválida!'
                  });
               } else {
                  ref.$toast.fire({
                     icon: 'error',
                     title: 'Erro: ' + error.response.status
                  })
               }
            } else {
               ref.$toast.fire({
                  icon: 'error',
                  title: error
               })
            }
         }).finally(() => {
            $('#modalEditarCurso').modal('hide')
            this.$store.dispatch('alternarTelaCarregamento', false)
         })
      },
      verPacotes : function (usuario) {
         this.modalPacote.pacotes = JSON.parse(JSON.stringify(usuario.pacote));
         this.modalPacote.idUsuario = JSON.parse(JSON.stringify(usuario.id));
         this.modalPacote.toggle = false
         $('#modalPacotes').modal('show')
      },
      // verPerfil : function (usuario) {
      //    this.perfil = {}
      //    this.perfil = JSON.parse(JSON.stringify(usuario));
      //    $('#modalPerfil').modal('show')
      // },
      deletarUser : function (user) {
         let ref = this;

         Swal.fire({
            icon: 'warning',
            title: 'Deletar ' + user.nomeCompleto + '?',
            confirmButtonText: 'Confirmar',
            showCancelButton: true,
            cancelButtonText: 'Cancelar'

         }).then((result) => {
            if (result.isConfirmed) {
               ref.$store.dispatch('alternarTelaCarregamento', true)
               
               ref.$axios({
                  method: 'post',
                  url: ref.urlRest +'servicosAdmin/deleteUsuario',
                  params: {
                     idUsuario: user.id
                  }
               }).then(function () {
                  ref.searchAllUsuarios()
                  ref.setUsuarioDeletado(user)

                  ref.$toast.fire({
                     icon: 'success',
                     title: 'Usuario deletado!',
							timer: 2500
                  })
               }).catch(function (error) {
                  if (error.response != undefined) {
                     if (error.response.status == 408) {
                        ref.$store.dispatch('deslogar')

                        ref.$toast.fire({
                           icon: 'warning',
                           title: 'Sessão expirada!'
                        });
                     } else {
                        ref.$toast.fire({
                           icon: 'error',
                           title: 'Erro: ' + error.response.status
                        })
                     }
                  } else {
                     ref.$toast.fire({
                        icon: 'error',
                        title: error
                     })
                  }
               }).finally(() => {
                  ref.$store.dispatch('alternarTelaCarregamento', false)
               })
            }
         });
		},
      removerPacote : function (pacote, index) {
         let ref = this;

         Swal.fire({
            icon: 'warning',
            title: 'Remover ' + pacote.nome + '?',
            confirmButtonText: 'Confirmar',
            showCancelButton: true,
            cancelButtonText: 'Cancelar'

         }).then((result) => {
            if (result.isConfirmed) {
               ref.$store.dispatch('alternarTelaCarregamento', true)
               
               ref.$axios({
                  method: 'post',
                  url: ref.urlRest +'servicosAdmin/deletePacoteUsuario',
                  params: {
                     idPacote: pacote.id,
                     idUsuario: this.modalPacote.idUsuario
                  }
               }).then(function () {
                  ref.modalPacote.pacotes.splice(index, 1) 
                  ref.searchAllUsuarios()

                  ref.$toast.fire({
                     icon: 'success',
                     title: 'Pacote removido!',
							timer: 2500
                  })
               }).catch(function (error) {
                  if (error.response != undefined) {
                     if (error.response.status == 408) {
                        ref.$store.dispatch('deslogar')

                        ref.$toast.fire({
                           icon: 'warning',
                           title: 'Sessão expirada!'
                        });
                     } else {
                        ref.$toast.fire({
                           icon: 'error',
                           title: 'Erro: ' + error.response.status
                        })
                     }
                  } else {
                     ref.$toast.fire({
                        icon: 'error',
                        title: error
                     })
                  }
               }).finally(() => {
                  ref.$store.dispatch('alternarTelaCarregamento', false)
               })
            }
         });
		},
      adicionarPacote : function (pacote) {
         let ref = this;

         Swal.fire({
            icon: 'warning',
            title: 'Adicionar ' + pacote.nome + '?',
            confirmButtonText: 'Confirmar',
            showCancelButton: true,
            cancelButtonText: 'Cancelar'

         }).then((result) => {
            if (result.isConfirmed) {
               ref.$store.dispatch('alternarTelaCarregamento', true)
               
               ref.$axios({
                  method: 'post',
                  url: ref.urlRest +'servicos/setPacoteGratuito',
                  headers: {
                     'Content-Type': 'application/json'
                  },
                  data: Array.of(pacote),
                  params: {
                     idUsuario: this.modalPacote.idUsuario
                  }
               }).then(function (response) {
                  ref.modalPacote.toggle = false

                  if (response.status != 205) {
                     ref.modalPacote.pacotes.push(pacote)
                     ref.searchAllUsuarios()

                     ref.$toast.fire({
                        icon: 'success',
                        title: 'Pacote adicionado!',
                        timer: 2500
                     })
                  } else {
                     ref.$toast.fire({
                        icon: 'error',
                        title: 'Pacote já adicionado!',
                        timer: 2500
                     })
                  }
               }).catch(function (error) {
                  if (error.response != undefined) {
                     if (error.response.status == 408) {
                        ref.$store.dispatch('deslogar')

                        ref.$toast.fire({
                           icon: 'warning',
                           title: 'Sessão expirada!'
                        });
                     } else {
                        ref.$toast.fire({
                           icon: 'error',
                           title: 'Erro: ' + error.response.status
                        })
                     }
                  } else {
                     ref.$toast.fire({
                        icon: 'error',
                        title: error
                     })
                  }
               }).finally(() => {
                  ref.$store.dispatch('alternarTelaCarregamento', false)
               })
            }
         });
		},
      showBars(resposta) {
         localStorage.setItem('showLine', resposta);
         this.showLine = resposta
      },
      calcularCursos : function () {
         for(let i = 0; i < this.usuario.pacote.length; i++) {
            for(let h = 0; h <= this.usuario.pacote[i].modulos.length; h++) {
               this.qtdCursos = h
            }
         }
      },
      calcularScripts : function () {
         for(let i = 0; i < this.usuario.pacote.length; i++) {
            for(let h = 0; h <= this.usuario.pacote[i].produtos.length; h++) {
               this.qtdScripts = h
            }
         }
      },
      formatDateTime(data) {
			const { hour, minute, second, dayOfMonth, month, year } = data;

			const formattedHour = String(hour).padStart(2, '0');
			const formattedMinute = String(minute).padStart(2, '0');
			const formattedSecond = String(second).padStart(2, '0');

			const formattedDayOfMonth = String(dayOfMonth).padStart(2, '0');

			const formattedTime = `${formattedHour}:${formattedMinute}:${formattedSecond}`;
			const formattedDate = `${formattedDayOfMonth}/${this.getMonthNumber(month)}/${year}`;

			return `${formattedDate} - ${formattedTime}`;
		},
		getMonthNumber(month) {
			const months = {
			JANUARY: '01',
			FEBRUARY: '02',
			MARCH: '03',
			APRIL: '04',
			MAY: '05',
			JUNE: '06',
			JULY: '07',
			AUGUST: '08',
			SEPTEMBER: '09',
			OCTOBER: '10',
			NOVEMBER: '11',
			DECEMBER: '12'
			};

			return months[month];
		},
      editUser(usuario) {
         this.user = {}
         this.user = JSON.parse(JSON.stringify(usuario));
         $('#modalEditUser').modal('show')
      },
      imageError : function (e) {
         this.$store.dispatch('imageError', e)
      },
   },
   mounted() {
      $('#search-nav .dropdown-menu').on('click', function(e) {
         if (e.target.nodeName != 'BUTTON' && e.target.parentElement.nodeName != 'BUTTON') {
            e.stopPropagation();
         }
      });

      this.$store.dispatch('searchAllPacotes')
   },
}

</script>

<style scoped>
#modalPacotes .card-buttons-top {
   display: flex;
	flex-wrap: wrap;
   justify-content: center;
   align-items: end;
   position: absolute;
   top: 0;
   bottom: 0;
   left: 0;
   right: 0;
   background: #0006;
   transition: 0.3s;
}

#modalPacotes .card-buttons-top:hover {
	background: rgba(0, 0, 0, 0.658);
}

#modalPacotes .card-buttons-top:hover #buttons {
	display: block !important;
}

#modalPacotes .card-buttons-top #buttons {
	display: none !important;
}

#modalPacotes .card-info-bottom * {
   filter: drop-shadow(0px 0px 2px rgb(0, 0, 0));
}

#modalPacotes .card-info-bottom a {
   color: #ddd;
   cursor: pointer;
}

#modalPacotes .card-info-bottom a:hover {
   text-decoration: underline !important;
}

#modalPacotes h6 {
   color: #ddd;
   margin-bottom: 10px !important;
   font-weight: 700 !important;
	filter: drop-shadow(0px 0px 2px rgb(0, 0, 0));
}

#modalPacotes p {
	color: #eee;
   font-size: 13px !important;
   margin-bottom: 0 !important;
	filter: drop-shadow(0px 0px 2px rgb(0, 0, 0));
	line-height: 1.4;
}

#modalPacotes img {
	aspect-ratio: 3/4;
	object-fit: cover;
}
</style>